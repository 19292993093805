import { NavLink, useNavigate } from "react-router-dom";
import dashboardIcon from "../../assets/img/menu-icons/dashboard-icon.svg";
import businessDetailsIcon from "../../assets/img/menu-icons/business-details-icon.svg";
import foodTruckIcon from "../../assets/img/menu-icons/food-truck-icon.svg";
import scheduleIcon from "../../assets/img/menu-icons/schedule-icon.svg";
import menuSetupIcon from "../../assets/img/menu-icons/menu-setup-icon.svg";
import userRoleIcon from "../../assets/img/menu-icons/user-role-icon.svg";
import reportsIcon from "../../assets/img/menu-icons/reports-icon.svg";
import qrCodeIcon from "../../assets/img/menu-icons/qr-code-icon.svg";
import socialMediaIcon from "../../assets/img/menu-icons/social-media-icon.svg";
import subscriptionIcon from "../../assets/img/menu-icons/subscription-icon.svg";
import supportIcon from "../../assets/img/menu-icons/support-icon.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusinessDetailsDirty,
  toggleSlider,
} from "../../store/reducers/settings_store";
import { useConfirmDialog } from "../../hooks/useConfirmationModel";

export const SideMenuList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessId = useSelector(
    (state) => state.userData.businessDetails.BusinessID
  );
  const businessDetailsDirty = useSelector(
    (state) => state.settingsData.isBusinessDetailsDirty
  );
  const [dialogueActions, setDialogueActions] = useState();
  const [message, setMessage] = useState("");
  const { ConfirmDialog, openModal } = useConfirmDialog();

  const [menus, setMenus] = useState([
    {
      id: 1,
      tittle: "Dashboard",
      image: dashboardIcon,
      to: "dashboard",
    },
    {
      id: 2,
      tittle: "Business Details",
      image: businessDetailsIcon,
      to: "business",
    },
    {
      id: 3,
      tittle: "Business Locations",
      image: foodTruckIcon,
      to: "food_truck",
    },
    // {
    //   id: 4,
    //   tittle: "Schedule",
    //   image: scheduleIcon,
    //   to: "schedule",
    // },
    {
      id: 5,
      tittle: "Menu Setup",
      image: menuSetupIcon,
      to: "menu-setup",
    },
    {
      id: 6,
      tittle: "User Role",
      image: userRoleIcon,
      to: "user-role",
    },
    {
      id: 7,
      tittle: "Reports",
      image: reportsIcon,
      to: "reports",
    },
    {
      id: 8,
      tittle: "QR Code",
      image: qrCodeIcon,
      to: "qr-generator",
    },
    {
      id: 9,
      tittle: "Social Media",
      image: socialMediaIcon,
      to: "social-media",
    },
    {
      id: 10,
      tittle: "Subscription",
      image: subscriptionIcon,
      to: "subscription",
    },
    {
      id: 11,
      tittle: "Support",
      image: supportIcon,
      to: "support",
    },
  ]);

  const CheckBusinessCompleted = async (event, link) => {
    dispatch(toggleSlider({ open: false }));
    if (link === "business") return;
    event.preventDefault();
    if (!businessId) {
      setDialogueActions({
        onCancel: {
          tittle: "Close",
        },
      });
      setMessage("Please complete Business Details to access this page");
      await openModal();
    } else if (businessId && businessDetailsDirty) {
      setDialogueActions({
        onCancel: {
          tittle: "Close",
        },
        onSubmit: {
          tittle: "Continue",
        },
      });
      setMessage(
        "Changes made will be lost if you navigate from this screen without Saving."
      );
      let result = await openModal();
      console.log(result);
      if (!result) return;
      dispatch(setBusinessDetailsDirty({ isBusinessDetailsDirty: false }));
      navigate(link);
    } else {
      navigate(link);
    }
  };

  return (
    <>
      {" "}
      <ConfirmDialog
        dialogueActions={dialogueActions}
        message={message}
        note=""
      />
      <div className="menu-side d-flex">
        {menus.map((menu) => (
          <NavLink
            className="menu-link d-flex pointer"
            key={menu.id}
            onClick={(e) => CheckBusinessCompleted(e, menu.to)}
            to={menu.to}
          >
            <img src={menu.image} />
            <p>{menu.tittle}</p>
          </NavLink>
        ))}
      </div>
    </>
  );
};
